














































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { questionService } from "@/services/question.service";
// eslint-disable-next-line no-unused-vars
import { QuestionEntity } from "@/entities/Question.entity";
// eslint-disable-next-line no-unused-vars
import { QuestionChoiceEntity } from "@/entities/QuestionChoice.entity";

@Component({
  components: {
    CardList: () => import("@/components/CardList.vue")
  }
})
export default class Questions extends Vue {
  questionsLoading: boolean = true;
  questions: QuestionEntity[] = [];
  questionsChoices: Record<number, QuestionChoiceEntity[]> = {};
  questionsHeaders = [
    { text: "Statement", value: "question_statement", sortable: false },
    { text: "Choices", value: "choices", sortable: false },
    { text: "Actions", value: "actions", sortable: false }
  ];

  choices = [
    {
      id: 1,
      statement: ""
    },
    {
      id: 2,
      statement: ""
    },
    {
      id: 3,
      statement: ""
    }
  ];

  counter = 10;

  questionStatement = "";
  valid = false;
  rules = {
    questionStatement: [(v: String) => !!v || "Question statement is required"]
  };
  questionCreated = false;

  dialog: boolean = false;
  questionToDelete: number = -1;

  async mounted() {
    await this.fetchData();
  }

  async fetchData() {
    this.questionsLoading = true;
    await this.getQuestions();
    await this.getQuestionsChoices();
    this.questionsLoading = false;
  }

  async getQuestions() {
    await questionService.getQuestions().then(response => {
      this.questions = response.data;
    });
  }

  async getQuestionsChoices() {
    this.questionsChoices = {};
    await questionService.getQuestionsChoices().then(response => {
      response.data.forEach(item => {
        if (item.question in this.questionsChoices) {
          this.questionsChoices[item.question].push(item);
        } else {
          this.questionsChoices[item.question] = [item];
        }
      });
    });
  }

  add() {
    this.choices.push({ id: this.counter++, statement: "" });
  }

  submit() {
    this.createQuestionWithChoices();
  }

  createQuestionWithChoices() {
    questionService
      .addQuestion(this.questionStatement)
      .then(response => {
        const createdQuestionId = response.data.id;
        this.choices.forEach(choice => {
          if (choice.statement != "") {
            questionService.addQuestionChoice(
              choice.statement,
              createdQuestionId
            );
          }
        });
      })
      .then(() => {
        this.questionCreated = true;
        this.$router.go(0);
      });
  }

  deleteQuestion() {
    questionService
      .deleteQuestion(this.questionToDelete)
      .then(async () => {
        await this.fetchData();
      })
      .catch(response => {
        alert(
          "Error while deleting this question. There may be a task that uses it. Check console for more details."
        );
        console.error(response);
      })
      .finally(() => {
        this.closeDialog();
      });
  }

  openDialog(questionId: number) {
    if (!this.dialog) {
      this.questionToDelete = questionId;
      this.dialog = true;
    }
  }

  closeDialog() {
    this.questionToDelete = -1;
    this.dialog = false;
  }
}
